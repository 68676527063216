.ui-page-theme-a .ui-bar-inherit.ui-footer {
  background-color: #fff;
  padding: 16px 20px;
  min-height: 56px;
  .footer-in {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-content: flex-end;
    flex-flow: row nowrap;
  }
  .footer-logo {
    flex: 0 0 67.5px;
    width: 67.5px;
    img {
      max-width: 100%;
    }
  }
  .footer-copy {
    font-size: 10px;
    font-weight: bold;
    color: #000;
    padding: 0;
    margin: 0;
    margin-left: auto;
  }
}
