@charset "utf-8";

// @import url('https://fonts.googleapis.com/css?family=Oswald:400,700');
// @import url('https://fonts.googleapis.com/css?family=Mada:400,500');
// @import url('https://fonts.googleapis.com/css?family=Fjalla+One');
// @import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Josefin+Sans');

@import 'setting';
// @import 'base/reset';
@import 'base/mixin';
@import 'base/optimize';
// @import 'base/base';

@import 'parts/menu';
@import 'parts/modal';
@import 'parts/login-modal';
@import 'parts/view-switcher';

@import 'layout/layout';
@import 'layout/header';
@import 'layout/footer';

@import 'pages/top';
@import 'pages/type';
@import 'pages/design';
@import 'pages/user';
@import 'pages/terms';
@import 'pages/complete';
