@mixin media($media-width){
  @if $media-width == sm {
    @media (max-width: $sm-width) {
      @content;
    }
  } @else if $media-width == lg {
    @media (min-width: $sm-width_u) {
      @content;
    }
  } @else if $media-width == lp-view-width {
    @media (min-width: 1040px) {
      @content;
    }
  }
}
@mixin reset {
  background: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  max-width: none;
  max-height: none;
  width: auto;
  height: auto;
  font-size: initial;
  letter-spacing: initial;
  line-height: 1;
  color: $base-font-color;
  font-family: $base-font-family;
}
