.page-complete {
  h5 {
   font-size: 20px; 
   font-weight: bold;
   color: #fff;
   line-height: 1;
   margin: 0;
   padding: 0;
   text-shadow: none;
  }
  h5.txt-msg {
    font-size: 16px; 
    padding: 0 25px;
    font-weight: normal;
    color: #fff;
    line-height: 1;
    margin: 0;
    text-shadow: none;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  .txt-msg-caution {
    margin-top: 20px;
    display: block;
    font-size: 13px; 
    border-top: 1px solid #626262;
    border-bottom: 1px solid #626262;
    padding: 10px 0px;
    font-feature-settings: "palt";
    text-align: justify;
    letter-spacing: 0.06em;
  }
  .txt-msg-caution-emp {
    font-size: 16px; 
    color: #e60000;
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
  .txt-label {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .txt-number {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    span {
      border-radius: 0;
      display: inline-block;
      line-height: 1;
      padding: 16px 30px;
      display: block;
      margin: 0 25px;
    }
  }
  #btnComplete {
    display: block;
    line-height: 1;
    display: block;
    margin: 0 25px;
    margin-bottom: 20px;
    background-color: #666 !important;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 16px 0;
    text-align: center;
  }
  #divSendImage {
    background-color: #fff;
  }
}
