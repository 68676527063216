.page-terms {
  #divTerms {
    padding: 30px 25px;
  }
  h4 {
    color: #ffffff;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    font-feature-settings: "palt";
    text-align: justify;
  }
  h5 {
    color: #ffffff;
    font-size: 14px;
    color: #fff;
    line-height: 1.5;
    font-feature-settings: "palt";
    text-align: justify;
    font-weight: 400;
  }
}
