ul,ol {
  list-style: none;
}
img {
  max-width: 100%;
  border-style:none;
}
a img {
  border-style:none;
}
a {
  outline:none;
}

.ui-body-a,
.ui-page-theme-a .ui-body-inherit,
html .ui-bar-a .ui-body-inherit,
html .ui-body-a .ui-body-inherit,
html body .ui-group-theme-a .ui-body-inherit,
html .ui-panel-page-container-a
{
  color: #000;
  text-shadow: none;
  font-family: $base-font-family;
}

.ui-overlay-a,
.ui-page-theme-a,
.ui-page-theme-a .ui-panel-wrapper {
  color: #000;
  text-shadow: none;
}

.lg-only {
  @include media(sm) {
    display: none;
  }
}
.sm-only {
  display: none;
  @include media(sm) {
    display: block;
  }
}

.ui-panel-position-right.ui-panel-display-overlay ,
.ui-panel-display-overlay {
  box-shadow: none;
}
.ui-corner-all {
  border-radius: 0;
}
.ui-btn-corner-all,
.ui-btn.ui-corner-all,
.ui-slider-track.ui-corner-all,
.ui-flipswitch.ui-corner-all,
.ui-li-count {
  border-radius: 0;
}
