.ui-input-text, .ui-input-search {
  border: none;
}
.page-design {
  #btnRestore, #btnLittleSmaller, #btnLittleBigger {
    font-size: 15px;
  }
  #divDesignArea {
    margin-bottom: 10px;
  }
  .ui-input-search,
  .ui-input-text,
  .ui-body-inherit {
    border: none;
  }
  .design-btns {
    max-width: 320px;
    margin: 0 auto;
    margin-bottom: 25px;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .design-adjusts {
    display: flex;
    align-items: strech;
    justify-content: space-between;
    align-content: flex-start;
    flex-flow: row nowrap;
    margin-bottom: 15px;
    .btn-adjust {
      flex: 0 0 48%;
      height: 43px;
      line-height: 43px;
      font-size: 15px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      &:hover {
        text-decoration: none;
      }
      &.btn-adjust-smaller {
        background: url("../images/design/btn_bg_adjust_left.png") no-repeat center center / contain;
      }
      &.btn-adjust-bigger {
        background: url("../images/design/btn_bg_adjust_right.png") no-repeat center center / contain;
      }
    }
  }
  .design-controll {
    margin-bottom: 10px;
    .btn-select {
      display: block;
      font-size: 15px;
      font-weight: bold;
      height: 43px;
      line-height: 43px;
      color: #ffffff;
      text-align: center;
      background: url("../images/design/btn_bg_select.png") no-repeat center center / contain;
      &:hover {
        text-decoration: none;
      }
      &.btn-adjust-smaller {
      }
    }
  }
  .design-controll-second-image {
    .slide-content {
      display: none;
    }
    .slide-content-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      flex-flow: row nowrap;
      #btnShowPlayer2 {
        flex: 1 0 auto;
        height: 44px;
        line-height: 44px;
        background: url("../images/design/btn_bg_second_image_left.png") no-repeat center center / contain;
        box-sizing: border-box;
        padding-left: 6px;
      }
      #btnSelectPhoto {
        flex: 1 0 auto;
        height: 44px;
        line-height: 44px;
        background: url("../images/design/btn_bg_second_image_right.png") no-repeat center center / contain;
        box-sizing: border-box;
        padding-right: 6px;
      }
    }
    .design-controll-title {
      .design-controll-second-image-btn {
        display: block;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        margin-bottom: 10px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        position: relative;
        text-decoration:none;
        background: url("../images/design/btn_bg_second_image.png") no-repeat center center / contain;
        // background-color: #121212;
      }
    }
    .txt-or {
      flex: 0 0 20px;
      text-align: center;
      white-space: nowrap;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      // margin-bottom: 12px;
      // margin: 6px 0;
    }
  }
  .design-controll-second-image.active {
    .design-controll-title {
      .design-controll-second-image-btn {
        background: url("../images/design/btn_bg_second_image_on.png") no-repeat center center / contain;
      }
    }
  }
  .design-flow {
    display: flex;
    align-items: strech;
    justify-content: space-between;
    align-content: flex-start;
    flex-flow: row nowrap;
    #btnBack.btn-back {
      flex: 0 0 33%;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: url("../images/design/btn_bg_back.png") no-repeat center center / contain;
      background-color: transparent !important;
      text-shadow: none;
      color: #fff !important;
      background-color: transparent !important;
    }
    #btnDecision.btn-next {
      flex: 0 0 67%;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: url("../images/design/btn_bg_next.png") no-repeat center center / contain;
      background-color: transparent !important;
    }
  }
  .design-goback {
    margin-top: 10px;
    .btn-back-to-type {
      display: block;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: url("../images/design/btn_bg_goback.png") no-repeat center center / contain;
      background-color: transparent !important;
    }
  }
}
#message-modal {
  .modal-dialog {
    .ui-block-a {
      padding-right: 10px;
      margin-bottom: 20px;
    }
    .ui-block-b {
      padding-left: 10px;
      margin-bottom: 20px;
    }
  }
}
#divPlayerList {
  .ui-block-a {
    padding-right: 20px;
    margin-bottom: 20px;
  }
  .ui-block-b {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  img {
    margin-top: 10px;
    max-width: 100%;
  }
  a.ui-link {
    display: block;
  }
  .player-name {
    display: block;
    font-size: 13px;
    color: #666;
    line-height: 1.2;
    font-feature-settings: "palt";
    letter-spacing: 0.02em;
  }
}

#divFrameList.frame-list {
  .ui-block-a {
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .ui-block-b {
    padding-left: 10px;
    margin-bottom: 20px;
  }
}
