#error-modal,
#back-modal,
#decision-modal {
  .ui-content {
    text-align: center;
  }
  .ui-title {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    text-align: left;
  }

  #btnOk {
    background-color: #ff0000 !important;
    border: 1px solid #ff0000 !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff !important;
    box-shadow: none;
    &:hover {
      opacity: 0.8;
      box-shadow: none;
    }
  }
  #btnCancel {
    background-color: #999 !important;
    border: 1px solid #999 !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff !important;
    box-shadow: none;
    &:hover {
      opacity: 0.8;
      box-shadow: none;
    }
  }
}
