.ui-popup-screen.in {
  background: #000;
  opacity: 0.5;
}
#login-modal-popup {
  border-radius: 0;
  box-shadow: none;
  .ui-body-a,
  .ui-popup {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .modal-dialog {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }
  .loginmodal-container {
    border: none;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }
  #btnLogin {
    margin-top: 15px;
    background-color: #ff0000 !important;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
  }
}
