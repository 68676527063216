//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;

$base-font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$mincho: "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;
$roboto: 'Roboto', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$josefin: 'Josefin Sans', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$quattrocento: 'Quattrocento', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$hannari: 'Hannari', "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HG明朝B", "MS Mincho", serif;

// /* Setting
// ------------------------*/
$max-width: 1000px;
$sm-width: 768px;
$sm-width_u: 769px;

$inner-padding: 6.67%;

$base-font-size: 62.5%;
$base-font-color: #000;
$anchor-base-font-color: #333;

// colors
$c-black: #505050;
$c-red: #d72d00;
$c-orange: #e95504;
$c-green: #22ac38;
