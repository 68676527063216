.view-switcher {
  margin:0;
  padding: 10px 10px;
  text-align: right;
  background-color: transparent;
  border: none;
  &::after {
    content: "〉";
    display: inline;
    margin-left: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
  }
  .ui-link {
    line-height: 1;
    margin:0;
    padding:0;
    font-size: 12px;
    font-weight: normal;
    text-shadow: none;
    background-color: transparent;
    border: none;
    color: #fff !important;
  }
}
