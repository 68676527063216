@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700|Josefin+Sans");
ul, ol {
  list-style: none;
}

img {
  max-width: 100%;
  border-style: none;
}

a img {
  border-style: none;
}

a {
  outline: none;
}

.ui-body-a,
.ui-page-theme-a .ui-body-inherit,
html .ui-bar-a .ui-body-inherit,
html .ui-body-a .ui-body-inherit,
html body .ui-group-theme-a .ui-body-inherit,
html .ui-panel-page-container-a {
  color: #000;
  text-shadow: none;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}

.ui-overlay-a,
.ui-page-theme-a,
.ui-page-theme-a .ui-panel-wrapper {
  color: #000;
  text-shadow: none;
}

@media (max-width: 768px) {
  .lg-only {
    display: none;
  }
}

.sm-only {
  display: none;
}

@media (max-width: 768px) {
  .sm-only {
    display: block;
  }
}

.ui-panel-position-right.ui-panel-display-overlay,
.ui-panel-display-overlay {
  box-shadow: none;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-btn-corner-all,
.ui-btn.ui-corner-all,
.ui-slider-track.ui-corner-all,
.ui-flipswitch.ui-corner-all,
.ui-li-count {
  border-radius: 0;
}

#menu-right {
  background-color: rgba(0, 0, 0, 0.95);
  padding-top: 70px;
}

#menu-right .ui-listview li {
  padding: 0 25px;
  position: relative;
}

#menu-right .ui-listview li::before {
  content: "";
  background: url("../images/common/icon_pop_list.svg") no-repeat center center/contain;
  position: absolute;
  top: 50%;
  left: 25px;
  width: 15px;
  height: 10px;
  margin-top: -5px;
}

#menu-right .ui-listview .ui-btn {
  background-color: transparent;
  padding: 12px 0;
  padding-left: 25px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
  border-color: #505050;
}

#menu-right .ui-listview .ui-btn:hover {
  background-color: transparent;
}

#error-modal .ui-content,
#back-modal .ui-content,
#decision-modal .ui-content {
  text-align: center;
}

#error-modal .ui-title,
#back-modal .ui-title,
#decision-modal .ui-title {
  font-size: 18px;
  font-weight: normal;
  color: #000;
  text-align: left;
}

#error-modal #btnOk,
#back-modal #btnOk,
#decision-modal #btnOk {
  background-color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
  box-shadow: none;
}

#error-modal #btnOk:hover,
#back-modal #btnOk:hover,
#decision-modal #btnOk:hover {
  opacity: 0.8;
  box-shadow: none;
}

#error-modal #btnCancel,
#back-modal #btnCancel,
#decision-modal #btnCancel {
  background-color: #999 !important;
  border: 1px solid #999 !important;
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
  box-shadow: none;
}

#error-modal #btnCancel:hover,
#back-modal #btnCancel:hover,
#decision-modal #btnCancel:hover {
  opacity: 0.8;
  box-shadow: none;
}

.ui-popup-screen.in {
  background: #000;
  opacity: 0.5;
}

#login-modal-popup {
  border-radius: 0;
  box-shadow: none;
}

#login-modal-popup .ui-body-a,
#login-modal-popup .ui-popup {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

#login-modal-popup .modal-dialog {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}

#login-modal-popup .loginmodal-container {
  border: none;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}

#login-modal-popup #btnLogin {
  margin-top: 15px;
  background-color: #ff0000 !important;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}

.view-switcher {
  margin: 0;
  padding: 10px 10px;
  text-align: right;
  background-color: transparent;
  border: none;
}

.view-switcher::after {
  content: "〉";
  display: inline;
  margin-left: 8px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
}

.view-switcher .ui-link {
  line-height: 1;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  text-shadow: none;
  background-color: transparent;
  border: none;
  color: #fff !important;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.outer {
  max-width: 1000px;
  margin: 0 auto;
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
}

body {
  background: #191919 url("../images/common/main_bg.png") no-repeat center 50px/100% auto;
  background-color: #191919 !important;
}

.ui-page {
  background-color: transparent;
}

.mobile-content {
  width: 100%;
  padding: 0;
}

#divMobilePage {
  background-color: transparent;
}

hgroup {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 30px 20px;
}

hgroup h1 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

hgroup h2 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.ui-page-theme-a .ui-bar-inherit.ui-header {
  border: none;
  color: #333;
  text-shadow: none;
  font-weight: 400;
  height: 50px;
  background-color: #000 !important;
}

.ui-page-theme-a .ui-bar-inherit.ui-header .header-log-link {
  border: none;
  padding: 0;
  margin: 0;
  display: inline;
  background-color: transparent;
  text-shadow: none;
  height: auto;
  width: auto;
  margin-top: 14px;
  margin-left: 22px;
}

.ui-page-theme-a .ui-bar-inherit.ui-header img#imgHeaderLogo {
  height: 20px;
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right {
  text-shadow: none;
  border: none;
  height: 50px;
  width: 50px;
  margin: 0;
  box-shadow: 0;
  border-radius: 0;
  background-color: #ff0000;
  top: 0;
  right: 0;
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right::before, .ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right::after {
  content: "";
  transition: background-color, transform 200ms ease-in-out 50ms;
  position: absolute;
  top: 50%;
  margin-top: -1.25px;
  left: 0;
  right: 0;
  width: 27px;
  height: 2.5px;
  background-color: #ffffff;
  display: block;
  margin: 0 auto;
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right::before {
  transform: translateY(-5px);
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right::after {
  transform: translateY(5px);
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right.panel-open::before {
  transform: translate(0, 0) rotate(-135deg) scale(1.06);
}

.ui-page-theme-a .ui-bar-inherit.ui-header .ui-btn-right.panel-open::after {
  transform: translate(0, 0) rotate(135deg) scale(1.06);
}

.ui-page-theme-a .ui-bar-inherit.ui-footer {
  background-color: #fff;
  padding: 16px 20px;
  min-height: 56px;
}

.ui-page-theme-a .ui-bar-inherit.ui-footer .footer-in {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-content: flex-end;
  flex-flow: row nowrap;
}

.ui-page-theme-a .ui-bar-inherit.ui-footer .footer-logo {
  flex: 0 0 67.5px;
  width: 67.5px;
}

.ui-page-theme-a .ui-bar-inherit.ui-footer .footer-logo img {
  max-width: 100%;
}

.ui-page-theme-a .ui-bar-inherit.ui-footer .footer-copy {
  font-size: 10px;
  font-weight: bold;
  color: #000;
  padding: 0;
  margin: 0;
  margin-left: auto;
}

.page-top {
  min-height: 561px;
  padding-top: 15px;
}

.page-top .sec-logos {
  margin-bottom: 16px;
}

.page-top .sec-logos .sec-logos-logo {
  margin: 0 auto;
  text-align: center;
}

.page-top .sec-logos .sec-logos-logo1 {
  width: 40px;
  margin-bottom: 10px;
}

.page-top .sec-logos .sec-logos-logo2 {
  width: 83px;
}

.page-top .sec-btns {
  position: relative;
  margin-bottom: 25px;
}

.page-top .sec-btns .sec-btns-top {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

.page-top .sec-btns .sec-btns-top::after {
  content: "";
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 221.5px;
  height: 53.5px;
  background: url("../images/top/logo_top_bg.svg") no-repeat center center/contain;
  z-index: 2;
}

.page-top .sec-btns .sec-btns-top .top-btn {
  position: relative;
  margin: 0 auto;
  margin-bottom: 8px;
  width: 171px;
  z-index: 3;
}

.page-top .sec-btns .sec-btns-middle {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: strech;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row nowrap;
}

.page-top .sec-btns .sec-btns-middle .top-btn-login,
.page-top .sec-btns .sec-btns-middle .top-btn-regist {
  width: 50%;
  flex: 0 0 50%;
  text-align: left;
}

.page-top .sec-btns .sec-btns-middle .top-btn-login a,
.page-top .sec-btns .sec-btns-middle .top-btn-regist a {
  display: block;
  max-width: 100px;
  margin: 0;
  margin-right: auto;
  transform: translate(60px, 0) rotate(0);
}

.page-top .sec-btns .sec-btns-middle .top-btn-login {
  text-align: right;
}

.page-top .sec-btns .sec-btns-middle .top-btn-login a {
  margin: 0;
  margin-left: auto;
  transform: translate(-60px, 0) rotate(0);
}

.page-top .sec-btns .sec-btns-bottom {
  position: relative;
  z-index: 1;
}

.page-top .sec-btns .sec-btns-bottom .top-btn {
  margin: 0 auto;
  text-align: center;
  width: 147px;
}

.page-top .top-slider {
  background-color: #191919;
  padding: 0;
  padding-bottom: 20px;
  /* Bullets */
}

.page-top .top-slider .swiper-container {
  padding: 20px 0;
}

.page-top .top-slider .swiper-wrapper {
  padding: 0;
  margin: 0;
}

.page-top .top-slider .swiper-slide {
  padding: 0 20px;
  margin: 0;
  text-align: center;
}

.page-top .top-slider .swiper-button-prev,
.page-top .top-slider .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 33px;
  height: 33px;
  margin-top: -16.5px;
  z-index: 10;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.page-top .top-slider .swiper-button-prev,
.page-top .top-slider .swiper-container-rtl .swiper-button-next {
  background-image: url("/assets/images/top/slider_btn_left.png");
  left: 5px;
}

.page-top .top-slider .swiper-button-next,
.page-top .top-slider .swiper-container-rtl .swiper-button-prev {
  background-image: url("/assets/images/top/slider_btn_right.png");
  right: 5px;
}

.page-top .top-slider .swiper-pagination-fraction,
.page-top .top-slider .swiper-pagination-custom,
.page-top .top-slider .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 2px;
  left: 0;
  width: 100%;
}

.page-top .top-slider .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #fff;
  opacity: 0.3;
}

.page-top .top-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: #d02929;
}

.page-top .top-slider .swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.page-top .top-slider .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.page-top .top-slider .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

.page-top .txt-terms {
  font-size: 12px;
  color: #cccccc;
  padding: 0 25px;
  font-weight: normal;
  line-height: 1.33;
  margin: 10px 0;
}

.page-type {
  min-height: 561px;
}

.page-type .ui-listview {
  padding: 0;
  margin: 0;
}

.page-type #ulType {
  padding: 0;
  margin: 0;
}

.page-type #ulType > li {
  background: none;
  border-bottom: 1px solid #000;
}

.page-type #ulType > li:last-child {
  border-bottom: none;
}

.page-type #ulType > li > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  flex-flow: row nowrap;
  background-color: transparent;
  border: none;
  height: 85px;
  padding: 0 0 0 6.25em;
  background: #000 url("../images/type/bg_list.png") no-repeat center center/cover;
  transition: opacity 300ms;
}

.page-type #ulType > li > a:hover {
  opacity: 0.8;
}

.page-type #ulType > li > a img {
  width: 70px;
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -35px;
  border-radius: 10px;
}

.page-type #ulType > li > a .txt {
  flex: 0 0 100%;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  text-shadow: none;
  padding: 0 25px 0 20px;
  position: relative;
  line-height: 1.5;
}

.page-type #ulType > li > a .txt::before {
  content: "";
  position: absolute;
  top: 5.5px;
  left: 0;
  width: 15px;
  height: 13.5px;
  background: url("../images/type/icon_listmarker01.svg") no-repeat center center/contain;
}

.ui-input-text, .ui-input-search {
  border: none;
}

.page-design #btnRestore, .page-design #btnLittleSmaller, .page-design #btnLittleBigger {
  font-size: 15px;
}

.page-design #divDesignArea {
  margin-bottom: 10px;
}

.page-design .ui-input-search,
.page-design .ui-input-text,
.page-design .ui-body-inherit {
  border: none;
}

.page-design .design-btns {
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.page-design .design-btns a:hover {
  text-decoration: none;
}

.page-design .design-adjusts {
  display: flex;
  align-items: strech;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.page-design .design-adjusts .btn-adjust {
  flex: 0 0 48%;
  height: 43px;
  line-height: 43px;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}

.page-design .design-adjusts .btn-adjust:hover {
  text-decoration: none;
}

.page-design .design-adjusts .btn-adjust.btn-adjust-smaller {
  background: url("../images/design/btn_bg_adjust_left.png") no-repeat center center/contain;
}

.page-design .design-adjusts .btn-adjust.btn-adjust-bigger {
  background: url("../images/design/btn_bg_adjust_right.png") no-repeat center center/contain;
}

.page-design .design-controll {
  margin-bottom: 10px;
}

.page-design .design-controll .btn-select {
  display: block;
  font-size: 15px;
  font-weight: bold;
  height: 43px;
  line-height: 43px;
  color: #ffffff;
  text-align: center;
  background: url("../images/design/btn_bg_select.png") no-repeat center center/contain;
}

.page-design .design-controll .btn-select:hover {
  text-decoration: none;
}

.page-design .design-controll-second-image .slide-content {
  display: none;
}

.page-design .design-controll-second-image .slide-content-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-flow: row nowrap;
}

.page-design .design-controll-second-image .slide-content-in #btnShowPlayer2 {
  flex: 1 0 auto;
  height: 44px;
  line-height: 44px;
  background: url("../images/design/btn_bg_second_image_left.png") no-repeat center center/contain;
  box-sizing: border-box;
  padding-left: 6px;
}

.page-design .design-controll-second-image .slide-content-in #btnSelectPhoto {
  flex: 1 0 auto;
  height: 44px;
  line-height: 44px;
  background: url("../images/design/btn_bg_second_image_right.png") no-repeat center center/contain;
  box-sizing: border-box;
  padding-right: 6px;
}

.page-design .design-controll-second-image .design-controll-title .design-controll-second-image-btn {
  display: block;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  margin-bottom: 10px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  position: relative;
  text-decoration: none;
  background: url("../images/design/btn_bg_second_image.png") no-repeat center center/contain;
}

.page-design .design-controll-second-image .txt-or {
  flex: 0 0 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
}

.page-design .design-controll-second-image.active .design-controll-title .design-controll-second-image-btn {
  background: url("../images/design/btn_bg_second_image_on.png") no-repeat center center/contain;
}

.page-design .design-flow {
  display: flex;
  align-items: strech;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row nowrap;
}

.page-design .design-flow #btnBack.btn-back {
  flex: 0 0 33%;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background: url("../images/design/btn_bg_back.png") no-repeat center center/contain;
  background-color: transparent !important;
  text-shadow: none;
  color: #fff !important;
  background-color: transparent !important;
}

.page-design .design-flow #btnDecision.btn-next {
  flex: 0 0 67%;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background: url("../images/design/btn_bg_next.png") no-repeat center center/contain;
  background-color: transparent !important;
}

.page-design .design-goback {
  margin-top: 10px;
}

.page-design .design-goback .btn-back-to-type {
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background: url("../images/design/btn_bg_goback.png") no-repeat center center/contain;
  background-color: transparent !important;
}

#message-modal .modal-dialog .ui-block-a {
  padding-right: 10px;
  margin-bottom: 20px;
}

#message-modal .modal-dialog .ui-block-b {
  padding-left: 10px;
  margin-bottom: 20px;
}

#divPlayerList .ui-block-a {
  padding-right: 20px;
  margin-bottom: 20px;
}

#divPlayerList .ui-block-b {
  padding-left: 20px;
  margin-bottom: 20px;
}

#divPlayerList img {
  margin-top: 10px;
  max-width: 100%;
}

#divPlayerList a.ui-link {
  display: block;
}

#divPlayerList .player-name {
  display: block;
  font-size: 13px;
  color: #666;
  line-height: 1.2;
  font-feature-settings: "palt";
  letter-spacing: 0.02em;
}

#divFrameList.frame-list .ui-block-a {
  padding-right: 10px;
  margin-bottom: 20px;
}

#divFrameList.frame-list .ui-block-b {
  padding-left: 10px;
  margin-bottom: 20px;
}

.page-user {
  min-height: 561px;
  padding: 25px 0 0;
}

.page-user .btn-area {
  padding: 0 25px;
  margin-bottom: 25px;
}

.page-user .btn-area #btnUserInfo {
  text-align: center;
  display: block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #2c409f !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: bold;
}

.page-user .btn-area #btnUserInfo:hover {
  opacity: 0.8;
  text-decoration: none;
}

.page-user .page-tit {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 0 25px;
  line-height: 1;
  margin-bottom: 10px;
}

.page-user .stamp-block {
  padding: 0 25px;
  background: transparent;
}

.page-user .stamp-block a.ui-link {
  display: inline-block;
  color: black;
  background: white;
  padding: 0;
  margin: 0;
}

.page-user .stamp-block .ui-block-b,
.page-user .stamp-block .ui-block-a {
  width: 48%;
  margin-bottom: 15px;
  background-color: transparent;
}

.page-user .stamp-block .ui-block-b {
  margin-left: 4%;
}

.page-user .txt-label {
  color: #fff;
  line-height: 1.8;
  margin: 0;
  padding: 0;
}

.page-user .txt-label .txt-no {
  font-size: 12px;
}

.page-user .txt-label .txt-num {
  font-size: 16px;
  margin-left: 3px;
}

.page-terms #divTerms {
  padding: 30px 25px;
}

.page-terms h4 {
  color: #ffffff;
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  font-feature-settings: "palt";
  text-align: justify;
}

.page-terms h5 {
  color: #ffffff;
  font-size: 14px;
  color: #fff;
  line-height: 1.5;
  font-feature-settings: "palt";
  text-align: justify;
  font-weight: 400;
}

.page-complete h5 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-shadow: none;
}

.page-complete h5.txt-msg {
  font-size: 16px;
  padding: 0 25px;
  font-weight: normal;
  color: #fff;
  line-height: 1;
  margin: 0;
  text-shadow: none;
  line-height: 1.5;
  margin-bottom: 30px;
}

.page-complete .txt-msg-caution {
  margin-top: 20px;
  display: block;
  font-size: 13px;
  border-top: 1px solid #626262;
  border-bottom: 1px solid #626262;
  padding: 10px 0px;
  font-feature-settings: "palt";
  text-align: justify;
  letter-spacing: 0.06em;
}

.page-complete .txt-msg-caution-emp {
  font-size: 16px;
  color: #e60000;
  display: block;
  margin-bottom: 5px;
  text-align: center;
}

.page-complete .txt-label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.page-complete .txt-number {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.page-complete .txt-number span {
  border-radius: 0;
  display: inline-block;
  line-height: 1;
  padding: 16px 30px;
  display: block;
  margin: 0 25px;
}

.page-complete #btnComplete {
  display: block;
  line-height: 1;
  display: block;
  margin: 0 25px;
  margin-bottom: 20px;
  background-color: #666 !important;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 16px 0;
  text-align: center;
}

.page-complete #divSendImage {
  background-color: #fff;
}
