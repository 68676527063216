.page-top {
  min-height: 561px;
  padding-top: 15px;
  .sec-logos {
    margin-bottom: 16px;
    .sec-logos-logo {
      margin: 0 auto;
      text-align: center;
    }
    .sec-logos-logo1 {
      width: 40px;
      margin-bottom: 10px;
    }
    .sec-logos-logo2 {
      width: 83px;
    }
  }

  .sec-btns {
    position: relative;
    margin-bottom: 25px;
    .sec-btns-top {
      position: relative;
      margin-bottom: 30px;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 221.5px;
        height: 53.5px;
        background: url("../images/top/logo_top_bg.svg") no-repeat center center / contain;
        z-index: 2;
      }
      .top-btn {
        position:relative;
        margin: 0 auto;
        margin-bottom: 8px;
        width: 171px;
        z-index: 3;
      }
    }
    .sec-btns-middle {
      // position: absolute;
      // width: 100%;
      // left: 0;
      // top: 105px;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: strech;
      justify-content: space-between;
      align-content: flex-start;
      flex-flow: row nowrap;
      .top-btn {
        // position: absolute;
        // top: 0;
        // width: 100px;
        &:first-child {
          // left: 50%;
          // transform: translate(58.5px, 0) rotate(0);
        }
        &:nth-child(2) {
          // right: 50%;
          // transform: translate(-58.5px, 0) rotate(0);
        }
      }
      .top-btn-login ,
      .top-btn-regist {
        width: 50%;
        flex: 0 0 50%;
        text-align: left;
        a {
          display: block;
          max-width: 100px;
          margin: 0;
          margin-right: auto;
          transform: translate(60px, 0) rotate(0);
        }
      }
      .top-btn-login {
        text-align: right;
        a {
          margin: 0;
          margin-left: auto;
          transform: translate(-60px, 0) rotate(0);
        }
      }
      .top-btn-line {
      }
    }
    .sec-btns-bottom {
      position: relative;
      z-index: 1;
      .top-btn {
        margin: 0 auto;
        text-align: center;
        width: 147px;
      }
    }
  }
  .top-slider {
    background-color: #191919;
    padding: 0;
    // margin-bottom: 15px;
    padding-bottom: 20px;
    .swiper-container {
      padding: 20px 0;
    }
    .swiper-wrapper {
      padding: 0;
      margin: 0;
    }
    .swiper-slide {
      padding: 0 20px;
      margin: 0;
      text-align: center;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      width: 33px;
      height: 33px;
      margin-top: -16.5px;
      z-index: 10;
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url("/assets/images/top/slider_btn_left.png");
      left: 5px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url("/assets/images/top/slider_btn_right.png");
      right: 5px;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 2px;
      left: 0;
      width: 100%;
    }
    /* Bullets */
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      background: #fff;
      opacity: 0.3;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #d02929;
    }
    .swiper-container-vertical > .swiper-pagination-bullets {
      right: 10px;
      top: 50%;
      -webkit-transform: translate3d(0px, -50%, 0);
      -moz-transform: translate3d(0px, -50%, 0);
      -o-transform: translate(0px, -50%);
      -ms-transform: translate3d(0px, -50%, 0);
      transform: translate3d(0px, -50%, 0);
    }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 5px 0;
      display: block;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }

  .txt-terms {
    font-size: 12px;
    color: #cccccc;
    padding: 0 25px;
    font-weight: normal;
    line-height: 1.33;
    margin: 10px 0;
  }
}
