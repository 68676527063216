#menu-right {
  background-color: rgba(0,0,0,0.95);
  padding-top: 70px;
  .ui-panel-inner {
    
  }
  .ui-listview {
    li {
      padding: 0 25px;
      position: relative;
      &::before {
        content: "";
        background: url("../images/common/icon_pop_list.svg") no-repeat center center / contain;
        position: absolute;
        top: 50%;
        left: 25px;
        width: 15px;
        height: 10px;
        margin-top: -5px;
      }
    }
    .ui-btn {
      background-color: transparent;
      padding: 12px 0;
      padding-left: 25px;
      font-size: 15px;
      font-weight: normal;
      color: #fff;
      border-color: #505050;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
