.page-type {
  min-height: 561px;
  .ui-listview {
    padding: 0;
    margin: 0;
  }
  #ulType {
    padding: 0;
    margin: 0;
    >li {
      background: none;
      border-bottom: 1px solid #000;
      &:last-child {
        border-bottom: none;
      }
      > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: flex-start;
        flex-flow: row nowrap;
        background-color: transparent;
        border: none;
        height: 85px;
        padding: 0 0 0 6.25em;
        background: #000 url("../images/type/bg_list.png") no-repeat center center / cover;
        transition: opacity 300ms;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 70px;
          position: absolute;
          top: 50%;
          left: 25px;
          margin-top: -35px;
          border-radius: 10px;
        }
        .txt {
          flex: 0 0 100%;
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
          text-shadow: none;
          padding: 0 25px 0 20px;
          position: relative;
          line-height: 1.5;
          &::before {
            content: "";
            position: absolute;
            top: 5.5px;;
            left: 0;
            width: 15px;
            height: 13.5px;
            background: url("../images/type/icon_listmarker01.svg") no-repeat center center / contain;
          }
        }
      }
    }
  }
}
