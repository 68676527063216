.clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.outer {
  max-width: $max-width;
  margin: 0 auto;
}

.inner {
  max-width: $max-width;
  margin: 0 auto;
}

body {
  background: #191919 url("../images/common/main_bg.png") no-repeat center 50px / 100% auto;
  background-color: #191919 !important;
}
.ui-page {
  background-color: transparent;
}

.mobile-content {
  width: 100%;
  padding: 0;
}

#divMobilePage {
  background-color: transparent;;
}

hgroup {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 30px 20px;
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
}
