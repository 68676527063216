.ui-page-theme-a .ui-bar-inherit.ui-header {
  border: none;
  color: #333;
  text-shadow: none;
  font-weight: 400;

  height: 50px;
  background-color: #000 !important;

  // ロゴ
  .header-log-link {
    border: none;
    padding: 0;
    margin: 0;
    display: inline;
    background-color: transparent;
    text-shadow: none;
    height: auto;
    width: auto;
    margin-top: 14px;
    margin-left: 22px;
  }
  img#imgHeaderLogo {
    height: 20px;
  }

  // メニューボタン
  .ui-btn-right {
    text-shadow: none;
    border: none;
    height: 50px;
    width: 50px;
    margin: 0;
    box-shadow: 0;
    border-radius: 0;
    background-color: #ff0000;
    top: 0;
    right: 0;

    &::before ,
    &::after {
      content: "";
      transition: background-color, transform 200ms ease-in-out 50ms;
      position: absolute;
      top: 50%;
      margin-top: -1.25px;
      left: 0;
      right: 0;
      width: 27px;
      height: 2.5px;
      background-color: #ffffff;
      display: block;
      margin: 0 auto;
    }
    &::before {
      transform: translateY(-5px);
    }
    &::after {
      transform: translateY(5px);
    }
    &.panel-open {
      &::before {
        transform: translate(0, 0) rotate(-135deg) scale(1.06);
      }
      &::after {
        transform: translate(0, 0) rotate(135deg) scale(1.06);
      }
    }
  }
}
