.page-user {
  min-height: 561px;
  padding: 25px 0 0;
  .btn-area {
    padding: 0 25px;
    margin-bottom: 25px;
    #btnUserInfo {
      text-align: center;
      display: block;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #2c409f !important;
      color: #fff !important;
      font-size: 15px;
      font-weight: bold;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
  .page-tit {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    padding: 0 25px;
    line-height: 1;
    margin-bottom: 10px;
  }
  .stamp-block {
    padding: 0 25px;
    background: transparent;
    a.ui-link {
      display: inline-block;
      color: black;
      background: white;
      padding: 0;
      margin: 0;
    }
    .ui-block-b,
    .ui-block-a {
      width: 48%;
      margin-bottom: 15px;
      background-color: transparent;
    }
    .ui-block-a {
    }
    .ui-block-b {
      margin-left: 4%;
    }
  }
  
  .txt-label {
    color: #fff;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    .txt-no {
      font-size: 12px;
    }
    .txt-num {
      font-size: 16px;
      margin-left: 3px;
    }
  }
}
